<template>
  <v-main id="main-wrapper">
    <notifications-area
      v-if="loggedIn || (!this.routeIsPrivate && !getShowLogin())"
      style="max-width: 750px;"
    />
    <v-container fluid>
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view :key="$route.path" />
      </transition>
    </v-container>
    <login-register-component
      v-if="!loggedIn"
    />
    <screen-deactivator v-if="disableScreen" />
  </v-main>
</template>

<script>
  import NotificationsArea from '@/components/NotificationsArea'
  import LoginRegisterComponent from '@/components/LoginRegisterComponent'
  import ScreenDeactivator from '@/components/ScreenDeactivator'
  import { authComputed } from '@/store/helpers'
  import { mapState, mapGetters } from 'vuex'

  export default {
    name: 'DefaultView',
    components: {
      NotificationsArea,
      LoginRegisterComponent,
      ScreenDeactivator,
    },
    computed: {
      ...authComputed,
      ...mapState('appGeneral', ['disableScreen']),
    },
    methods: {
      ...mapGetters('login', ['getShowLogin']),
    },
  }
</script>

<style lang="scss">
#main-wrapper{
  position: relative;
}
.fade-enter{
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active{
  transition: opacity 0.3s ease;
}

.fade-leave{
  opacity: 0;
}
</style>
